import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import Content from '../../components/layout/Content';
import BottomNav from '../../components/layout/BottomNav';

import { createCustomerAndScanIn } from '../../actions/registerCustomerActions';
import { CLEAR_CREATE_CUSTOMER } from '../../types/registerCustomerTypes';

const NewCustomer = () => {
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const registerCustomerSelector = useSelector((state) => state.registerCustomer);
    const { showTableScan } = authSelector;
    const { loading, errors, success } = registerCustomerSelector;

    const termsEl = useRef(null);
    const [termsError, setTermsError] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [repeatEmail, setRepeatEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [table, setTable] = useState('');

    const onSubmit = (e) => {
        e.preventDefault();

        if (termsEl.current.checked) {
            setTermsError(null);
            dispatch(createCustomerAndScanIn(firstName, lastName, email, repeatEmail, phone, table));
        } else {
            setTermsError('Please check the terms to continue.');
        }
    }

    useEffect(() => {
        return () => {
            dispatch({ type: CLEAR_CREATE_CUSTOMER });
        };
    }, []);

    return (
        <Fragment>
            <Header />
            <Menu />
            <Content>
                <h1>Manual Entry</h1>
                {!success ? (
                    <Fragment>
                        <span className="login__welcome">Please fill out all fields</span>
                        <form className="login__form" onSubmit={onSubmit}>
                            <div className="form-group">
                                <label className="form__label" htmlFor="firstName">First Name</label>
                                <input className="form__input" type="text" id="firstName" name="firstName" value={firstName} autoComplete={false} onChange={(e) => setFirstName(e.target.value)} />
                                <div className="error">{errors?.firstname?.message}</div>
                            </div>
                            <div className="form-group">
                                <label className="form__label" htmlFor="lastName">Last Name</label>
                                <input className="form__input" type="text" id="lastName" name="lastName" value={lastName} autoComplete={false} onChange={(e) => setLastName(e.target.value)} />
                                <div className="error">{errors?.lastname?.message}</div>
                            </div>
                            <div className="form-group">
                                <label className="form__label" htmlFor="email">Email</label>
                                <input className="form__input" type="text" id="email" name="email" value={email} autoComplete={false} onChange={(e) => setEmail(e.target.value)} />
                                <div className="error">{errors?.email?.message}</div>
                            </div>
                            <div className="form-group">
                                <label className="form__label" htmlFor="repeatEmail">Retype Email</label>
                                <input className="form__input" type="text" id="repeatEmail" name="repeatEmail" value={repeatEmail} autoComplete={false} onChange={(e) => setRepeatEmail(e.target.value)} />
                                <div className="error">{errors?.repeat_email?.message}</div>
                            </div>
                            <div className="form-group">
                                <label className="form__label" htmlFor="phone">Mobile Phone</label>
                                <input className="form__input" type="text" id="phone" name="phone" value={phone} autoComplete={false} onChange={(e) => setPhone(e.target.value)} />
                                <div className="error">{errors?.phone?.message}</div>
                            </div>
                            {showTableScan && (
                                <div className="form-group">
                                    <label className="form__label" htmlFor="table">Table Number</label>
                                    <input className="form__input" type="text" id="table" name="table" value={table} autoComplete={false} onChange={(e) => setTable(e.target.value)} />
                                    <div className="error">{errors?.table?.message}</div>
                                </div>
                            )}
                            <div className="form-group--checkbox">
                                <label htmlFor="terms" className="form__label">Customer has acknowledged their contact details will be retained for future check in ease of use and marketing purposes. they'll be able to update your communication preferences from your profile.
                                    <input className="form__checkbox" type="checkbox" id="terms" name="terms" ref={termsEl} />
                                </label>
                                <div className="error">{termsError}</div>
                            </div>
                            <div className="form__buttons">
                                <button aria-label="Register" disabled={loading} className="form__button form__button--inline" type="submit">Register <PulseLoader loading={loading} color={'#ffffff'} css={'margin-left: 8px'} size={5} /></button>
                            </div>
                        </form>

                    </Fragment>
                ) : (
                    <Fragment>
                        <div>
                            {phone ? (
                                <p style={{ textAlign: 'center' }}>{`${firstName} ${lastName} has been registered. A verification text message has been sent to ${phone}`}</p>
                            ) : (
                                <p style={{ textAlign: 'center' }}>{`${firstName} ${lastName} has been registered. A verification email has been sent to ${email}`}</p>
                            )}
                        </div>
                    </Fragment>
                )}
            </Content>
            <BottomNav />
        </Fragment>
    )
}

export default NewCustomer;
